import React, { useEffect, useState } from 'react';

import firebase from 'firebase';
import loadable from '@loadable/component';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import '../styles/main.scss';

const GDPRBanner = loadable(() =>
  import('./Helpers-components/GDPR-component/GDPR-banner-component'),
);

export function Layout({ isForum, isHomepage, children, topMenu, activeDocMeta }) {
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
  const [gDRPVisible, setGDRPVisible] = useState(false); // Local signed-in state.
  const isBrowser = typeof window !== 'undefined';

  // Redirect to the English version of the site

  useEffect(() => {
    if (isBrowser) {
      setTimeout(() => {
        if (window.location.pathname.includes('/es-es')) {
          window.location.href = window.location.href.replace('/es-es', '');
        }
        // /ru
        if (window.location.pathname.includes('/ru')) {
          window.location.href = window.location.href.replace('/ru', '');
        }
      }, 500);
    }
  }, []);

  setTimeout(() => {
    setGDRPVisible(true);
  }, 3000);

  useEffect(() => {
    let isMounted = true;
    if (isBrowser && isMounted) {
      const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
        setIsSignedIn(!!user);
      });
    }
    return () => {
      isMounted = false;

      if (isBrowser) {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
          setIsSignedIn(!!user);
        });
        unregisterAuthObserver();
      }
    };
  }, [isSignedIn]);

  return (
    <div className="d-flex justify-content-between flex-column min-vh-100">
      <Header
        topMenu={topMenu}
        activeDocMeta={activeDocMeta}
        isHomepage={isHomepage}
        isForum={isForum}
        isSignedIn={isSignedIn}
      />
      {children}
      <Footer activeDocMeta={activeDocMeta} isForum={isForum} />
      <GDPRBanner visible={gDRPVisible} activeDocMeta={activeDocMeta} />
    </div>
  );
}
